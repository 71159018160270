<template>
  <div class="h-full w-full" :data-test="dataTest">
    <TaskAssigneePopover
      ref="assigneeRef"
      class="w-full h-full"
      button-class="flex items-center min-h-8"
      :current-assignee="value"
      :board-id="boardId"
      :disabled="disabled"
      :trigger-class="triggerClass"
      data-test="task-assignee-popover"
      @update:open="onUpdateOpen"
      @select="handleSelect"
      @remove="handleRemove"
    >
      <template v-if="value">
        <UButton
          variant="soft"
          color="white"
          class="text-gray-900 w-full h-full font-normal !ring-0"
          size="2xs"
          data-test="assignee-btn"
          :disabled="disabled"
        >
          <template #leading>
            <Avatar :id="value.id" :src="value.photo" :name="value.fullName" size="2xs" />
          </template>
          <Tooltip :text="value.fullName" data-test="assignee-name">
            <template #default="{ getTextRef }">
              <span :ref="getTextRef" class="line-clamp-1 break-all text-start">{{ value.fullName }}</span>
            </template>
          </Tooltip>
        </UButton>
      </template>
      <template v-else>
        <UButton
          variant="soft"
          size="2xs"
          color="white"
          class="text-gray-400 w-full h-full !ring-0"
          :disabled="disabled"
        >
          <Icon :size="16" name="heroicons:user-circle" class="ml-0.5" />
        </UButton>
      </template>
    </TaskAssigneePopover>
  </div>
</template>

<script lang="ts" setup>
import type { TaskFieldRef } from '#field/types'
import type { Assignee } from '#auth/types'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  taskId: {
    type: String,
    required: true,
  },
  boardId: {
    type: String,
    required: true,
  },
  value: {
    type: [Object, null] as PropType<Assignee | null>,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  triggerClass: {
    type: Function as PropType<(active: boolean) => string[]>,
  },
  dataTest: {
    type: String,
  },
})
const emit = defineEmits<{
  (e: 'update:open', value: boolean): void
}>()

const { selectedTaskIds } = useListViewSharedState()
const { updateTask, updateTasks } = useUpdateTask()

const assigneeRef = ref()
const isPopoverOpen = ref(false)

const handleSelect = (assignee: string) => {
  updateTasks([props.taskId], { assignee }, true)
  emit('update:open', false)
}

const handleRemove = () => {
  emit('update:open', false)

  if (selectedTaskIds.value.has(props.taskId)) {
    return updateTasks(Array.from(selectedTaskIds.value), { assignee: null })
  }

  updateTask(props.taskId, { assignee: null })
}

const onUpdateOpen = (open: boolean) => {
  isPopoverOpen.value = open
  emit('update:open', open)
}

defineExpose<TaskFieldRef>({
  open: () => {
    assigneeRef.value?.open()
  },
  close: () => {
    assigneeRef.value?.close()
  },
  delete: () => {
    handleRemove()
  },
})
</script>
